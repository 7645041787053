// app/components/SmoothTransition.tsx
"use client";

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { usePathname } from "next/navigation";

export default function SmoothTransition({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(true);
  const pathname = usePathname();

  // Désactiver l'animation pour les pages 
  const disableAnimation = pathname.startsWith("/dashboard") || pathname.startsWith("/portal");


  useEffect(() => {
    if (disableAnimation) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 600);

    return () => clearTimeout(timer);
  }, [pathname, disableAnimation]);

  if (disableAnimation) {
    return <>{children}</>;
  }

  return (
    <>
      <AnimatePresence mode="wait">
        {loading && (
          <motion.div
            key="transitionOverlay"
            initial={{ x: "-100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="fixed inset-0 z-40 bg-gradient-to-br from-AWS_blue to-AWS_black"
          />
        )}
      </AnimatePresence>
      {!loading && children}
    </>
  );
}
