// src/lib/db/session.ts
import { supabase } from "../supabaseClient";

export async function getSessionUser() {
  const { data: sessionData, error } = await supabase.auth.getSession();

  if (error || !sessionData?.session?.user?.id) {
    console.warn("⚠️ Aucune session valide. Pas de rafraîchissement automatique.");
    return { userId: null, session: null };
  }

  return { userId: sessionData.session.user.id, session: sessionData.session };
}
