"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaBars, FaTimes } from "react-icons/fa";

export default function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  return (
    <header
      className={`fixed top-0 w-full z-50 text-white transition-all duration-300 ${
        scrolled
          ? "bg-AWS_darkBlue py-[8px] shadow-[0_2px_6px_rgba(0,0,0,0.15)]"
          : "bg-AWS_blue py-[15px]"
      }`}
    >
      <div className="container mx-auto px-[30px] flex justify-between items-center">
        {/* Gauche : logo + menu desktop */}
        <div className="flex items-center">
          <Link
            href="/"
            aria-label="Accueil"
            className="mr-[20px] transform transition-transform duration-300 hover:rotate-[10deg]"
          >
            <img
              src="/logo/AWSS-Logo_AWSS-White-Full.svg"
              alt="Logo"
              className="w-[40px] h-[40px] object-contain"
            />
          </Link>
          {/* Menu de navigation - visible sur md et plus */}
          <nav aria-label="Navigation principale" className="hidden md:block">
            <ul className="flex list-none m-0 p-0 space-x-[15px]">
              <li>
                <Link
                  href="/"
                  className="relative font-medium text-white transition-colors duration-300 hover:text-gray-300
                    after:content-[''] after:absolute after:left-0 after:-bottom-[3px] after:w-full after:h-[2px] after:bg-white after:scale-x-0 after:origin-left after:transition-transform after:duration-300 hover:after:scale-x-100"
                >
                  Accueil
                </Link>
              </li>
              <li>
                <Link
                  href="/services"
                  className="relative font-medium text-white transition-colors duration-300 hover:text-gray-300
                    after:content-[''] after:absolute after:left-0 after:-bottom-[3px] after:w-full after:h-[2px] after:bg-white after:scale-x-0 after:origin-left after:transition-transform after:duration-300 hover:after:scale-x-100"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  href="/contact"
                  className="relative font-medium text-white transition-colors duration-300 hover:text-gray-300
                    after:content-[''] after:absolute after:left-0 after:-bottom-[3px] after:w-full after:h-[2px] after:bg-white after:scale-x-0 after:origin-left after:transition-transform after:duration-300 hover:after:scale-x-100"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        {/* Droite : téléphone, espace pro & réseaux sociaux */}
        <div className="flex items-center">
          {/* Menu secondaire desktop */}
          <nav aria-label="Navigation secondaire" className="hidden md:block">
            <ul className="flex list-none m-0 p-0 space-x-[15px] pr-5">
              <li>
                <Link
                  href="/espace-pro"
                  className={`transition-colors duration-300 text-white px-[12px] py-[8px] rounded font-semibold ${
                    scrolled
                      ? "bg-AWS_blue hover:bg-AWS_darkBlue"
                      : "bg-AWS_darkBlue hover:bg-AWS_blue"
                  }`}
                >
                  Espace Pro
                </Link>
              </li>
            </ul>
          </nav>

          {/* Bouton hamburger pour mobile */}
          <button
            onClick={toggleMobileMenu}
            className="md:hidden text-white text-[1.5rem] focus:outline-none mr-4"
            aria-label="Menu mobile"
          >
            {mobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>

          {/* Téléphone et icônes - visibles uniquement sur desktop */}
          <a
            href="tel:+33635235721"
            className="hidden md:block mr-[20px] font-medium transition-colors duration-300 hover:text-[#d0d0d0]"
          >
            06 35 23 57 21
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61573524443469"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
            className="hidden md:block ml-[10px] text-[1.2rem] transition duration-300 hover:text-[#d0d0d0] hover:scale-110"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/alexwebsolutions/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
            className="hidden md:block ml-[10px] text-[1.2rem] transition-transform duration-300 hover:text-[#d0d0d0] hover:scale-110"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/company/alex-web-solutions"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
            className="hidden md:block ml-[10px] text-[1.2rem] transition duration-300 hover:text-[#d0d0d0] hover:scale-110"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>

      {/* Menu mobile */}
      {mobileMenuOpen && (
        <nav aria-label="Mobile Navigation" className="md:hidden bg-AWS_blue">
          <ul className="flex flex-col list-none m-3 p-4 space-y-4">
            <li>
              <Link
                href="/"
                className="block font-medium text-white transition-colors duration-300 hover:text-gray-300"
                onClick={() => setMobileMenuOpen(false)}
              >
                Accueil
              </Link>
            </li>
            <li>
              <Link
                href="/services"
                className="block font-medium text-white transition-colors duration-300 hover:text-gray-300"
                onClick={() => setMobileMenuOpen(false)}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                href="/contact"
                className="block font-medium text-white transition-colors duration-300 hover:text-gray-300"
                onClick={() => setMobileMenuOpen(false)}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                href="/espace-pro"
                className="block transition-colors duration-300 text-white px-[12px] py-[8px] rounded font-semibold bg-AWS_darkBlue hover:bg-AWS_blue"
                onClick={() => setMobileMenuOpen(false)}
              >
                Espace Pro
              </Link>
            </li>
            <li>
              <a
                href="tel:+33635235721"
                className="block font-medium transition-colors duration-300 hover:text-[#d0d0d0]"
              >
                06 35 23 57 21
              </a>
            </li>
            <li className="flex space-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=61573524443469"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
                className="text-[1.2rem] transition-transform duration-300 hover:text-[#d0d0d0] hover:scale-110"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/alexwebsolutions/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
                className="text-[1.2rem] transition duration-300 hover:text-[#d0d0d0] hover:scale-110"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/alex-web-solutions"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
                className="text-[1.2rem] transition duration-300 hover:text-[#d0d0d0] hover:scale-110"
              >
                <FaLinkedinIn />
              </a>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
}
