"use client";

import React, { useEffect, useState } from "react";

export default function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");

    // On ne redemande que si pas encore accepté
    if (consent !== "accepted") {
      setShowBanner(true);
    }
  }, []);

  const handleConsent = (value: string) => {
    // On stocke uniquement si l’utilisateur accepte
    if (value === "accepted") {
      localStorage.setItem("cookieConsent", value);
    } else {
      localStorage.removeItem("cookieConsent"); // Efface pour qu'on redemande
    }

    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-4 left-4 right-4 md:left-6 md:right-6 bg-AWS_black text-white text-sm px-6 py-4 rounded-xl shadow-xl z-[999] max-w-xl mx-auto">
      <div className="flex flex-col md:flex-row items-center justify-between gap-4">
        <p className="text-sm text-gray-300">
          Ce site utilise des cookies pour améliorer votre expérience. Vous pouvez accepter ou refuser.
        </p>
        <div className="flex gap-3">
          <button
            onClick={() => handleConsent("accepted")}
            className="px-4 py-2 rounded-lg bg-AWS_blue hover:bg-AWS_darkBlue text-white transition"
          >
            Accepter
          </button>
          <button
            onClick={() => handleConsent("refused")}
            className="px-4 py-2 rounded-lg bg-AWS_grey hover:bg-gray-600 text-white transition"
          >
            Refuser
          </button>
        </div>
      </div>
    </div>
  );
}
