// src/lib/db/users.ts
import { supabase } from "../supabaseClient";

export interface User {
  id: string;
  name: string;
  email: string;
  phone?: string;
  role: "client" | "admin";
  created_at: string;
  avatar_path?: string;
}

export async function checkUserIsAdmin(userId: string): Promise<boolean> {
  if (!userId) {
    console.error("❌ Aucun userId fourni à checkUserIsAdmin");
    return false;
  }
  const { data, error } = await supabase
    .from("users")
    .select("role")
    .eq("id", userId)
    .maybeSingle();

  if (error) {
    console.error("❌ Erreur lors de la vérification du rôle utilisateur:", error.message);
    return false;
  }
  return data?.role === "admin";
}

/**
 * Récupère un utilisateur par son email.
 */
export async function getUserByEmail(email: string): Promise<User | null> {
  const { data, error } = await supabase
    .from("users")
    .select("*")
    .eq("email", email)
    .maybeSingle();

  if (error) {
    throw new Error(error.message);
  }
  return data;
}

/**
 * Récupère le nom de l'utilisateur par son ID.
 */
export async function getUserById(userId: string): Promise<string | null> {
  const { data, error } = await supabase
    .from("users")
    .select("name")
    .eq("id", userId)
    .maybeSingle();

  if (error) {
    throw new Error(error.message);
  }
  return data?.name || null;
}

/**
 * Récupère l'URL publique de l'avatar de l'utilisateur.
 * Retourne "/default-avatar.png" s'il n'est pas défini.
 */
export async function getUserAvatar(userId: string): Promise<string> {
  if (!userId) return "/default-avatar.png";

  const { data, error } = await supabase
    .from("users")
    .select("avatar_path")
    .eq("id", userId)
    .maybeSingle();

  if (error || !data?.avatar_path) {
    console.warn("⚠️ Aucun avatar trouvé, utilisation de l'avatar par défaut.");
    return "/default-avatar.png";
  }

  const publicUrl = supabase.storage.from("avatars").getPublicUrl(data.avatar_path);
  return publicUrl.data.publicUrl || "/default-avatar.png";
}

export async function uploadAvatar(userId: string) {
  if (!userId) {
    console.error("❌ Aucun userId fourni pour l'upload de l'avatar.");
    return null;
  }
}

/**
 * Insère un nouvel utilisateur avec les informations fournies.
 */
export async function insertUser(userData: { name: string; email: string; phone: string; }): Promise<User | null> {
  const { data, error } = await supabase
    .from("users")
    .insert([{
      name: userData.name,
      email: userData.email,
      phone: userData.phone,
      role: "client"
    }])
    .maybeSingle();

  if (error) {
    throw new Error(error.message);
  }
  return data;
}
