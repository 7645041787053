"use client";

import { useRouter } from "next/navigation";
import { supabase } from "../lib/supabaseClient";

export default function LogoutButton() {
  const router = useRouter();

  async function handleLogout() {
    await supabase.auth.signOut();
    router.push("/espace-pro");
  }

  return (
    <button onClick={handleLogout} className="w-full bg-blue text-white py-2 px-4 rounded hover:bg-blueDark">
      Se déconnecter
    </button>
  );
}
