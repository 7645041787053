"use client";

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { usePathname } from "next/navigation"; // Importer usePathname

const EmailPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showBubble, setShowBubble] = useState(false);
  const [email, setEmail] = useState("");
  const pathname = usePathname(); // Utiliser usePathname pour obtenir le chemin actuel

  // Vérifie si l'utilisateur a déjà fermé le pop-up dans la session et si on est sur la page /services
  useEffect(() => {
    const dismissed = sessionStorage.getItem("emailPopupDismissed");
    if (pathname === "/services" && !dismissed) {
      // Attendre 3 secondes avant d'afficher le pop-up
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 3000); // 3000 ms = 3 secondes

      // Nettoyage du timer au cas où le composant serait démonté avant que le délai ne soit atteint
      return () => clearTimeout(timer);
    } else {
      setShowBubble(true);
    }
  }, [pathname]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ferme immédiatement le pop-up pour éviter plusieurs clics
    sessionStorage.setItem("emailPopupDismissed", "true");
    setShowPopup(false);
    setShowBubble(true);

    try {
      const res = await fetch("/api/brochure", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      if (!res.ok) {
        console.error("Erreur lors de l'envoi de l'email");
      } else {
        console.log("Email envoyé avec succès");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email:", error);
    }
  };

  const handleClose = () => {
    sessionStorage.setItem("emailPopupDismissed", "true");
    setShowPopup(false);
    setShowBubble(true);
  };

  const openPopup = () => {
    setShowPopup(true);
    setShowBubble(false);
  };

  return (
    <>
      {/* Pop-up modal */}
      <AnimatePresence>
        {showPopup && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
          >
            <motion.div
              initial={{ scale: 0.8, y: -20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.8, y: -20 }}
              transition={{ duration: 0.3 }}
              className="relative bg-gradient-to-br from-purple-600 via-blue-500 to-AWS_black p-8 rounded-xl shadow-2xl w-full max-w-md text-center text-white"
            >
              {/* Bouton de fermeture */}
              <button
                onClick={handleClose}
                className="absolute top-3 right-3 text-white text-3xl font-bold bg-white bg-opacity-20 rounded-full p-2 hover:bg-opacity-40 transition duration-300 focus:outline-none"
              >
                &times;
              </button>
              <h2 className="text-2xl font-bold mb-4">Recevez notre brochure</h2>
              <p className="mb-6 text-sm">
                Laissez votre adresse email pour recevoir notre brochure et découvrir nos offres exclusives.
              </p>
              <form onSubmit={handleSubmit} className="space-y-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Votre email"
                  required
                  className="w-full px-4 py-2 rounded border border-gray-300 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue"
                />
                <div>
                  <div className="text-xs text-white text-left mb-2 pt-5">
                    En soumettant ce formulaire, vous acceptez de recevoir notre brochure ainsi que des emails occasionnels de la part de Alex Web Solutions (offres, conseils, informations).  
                    Vous pourrez vous désinscrire à tout moment.
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-gradient-to-r from-purple-600 to-blue-500 hover:from-purple-700 hover:to-blue-600 text-white font-semibold py-3 px-8 rounded-full transition duration-300"
                  >
                    Envoyer
                  </button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Bulle pour réafficher le pop-up */}
      <AnimatePresence>
        {showBubble && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
            className="fixed bottom-5 right-5 z-40 flex flex-col items-center"
          >
            <div className="mb-2 bg-AWS_blue text-AWS_lightText px-2 py-2 rounded-lg text-xs">
              Récupérez notre brochure
            </div>
            <button
              onClick={openPopup}
              className="bg-AWS_blue hover:bg-AWS_darkBlue text-AWS_lightText w-12 h-12 rounded-full shadow-lg flex items-center justify-center text-2xl transition focus:outline-none"
            >
              +
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default EmailPopup;
