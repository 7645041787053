// app/components/HeaderSwitcher.tsx
"use client";

import { usePathname } from "next/navigation";
import Header from "./Header"; 
import PortalHeader from "./PortalHeader"; 
import EmailPopup from "../components/EmailPopup";

export default function HeaderSwitcher() {
  const pathname = usePathname();
  const isPortal = pathname.startsWith("/portal");

  return isPortal ? (
    <>
      <PortalHeader />
      
    </>
  ) : (
    <>
      <Header />
      <EmailPopup />
    </>
  );
}
