"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import LogoutButton from "./LogoutButton"; // ✅ Vérifie que LogoutButton est bien exporté par défaut
import { motion } from "framer-motion";
import { usePathname } from "next/navigation";
import { getSessionUser } from "../lib/db/session"; // ✅ Vérifie que ce fichier existe et qu'il exporte bien getSessionUser
import { getUserById } from "../lib/db/users"; // ✅ Vérifie que ces fonctions existent bien


export default function PortalHeader() {
  const [userName, setUserName] = useState("Chargement...");
  const [userAvatar, setUserAvatar] = useState<string>("/default-avatar.png");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    if (!pathname.startsWith("/portal")) return;

    const fetchUserData = async () => {
      const { userId } = await getSessionUser();
      if (!userId) {
        setUserName("Client");
        setUserAvatar("/default-avatar.png");
        return;
      }

      const name = await getUserById(userId);

      setUserName(name ?? "Client");
    };

    fetchUserData();
  }, [pathname]);

  if (!pathname.startsWith("/portal")) return null;

  return (
    <header className="w-full bg-neutral-900 py-4 fixed top-0 z-[990]">
      <nav className="container mx-auto px-4 flex items-center justify-between">
        {/* 🔹 Avatar + Nom */}
        <Link href="/portal" className="flex items-center space-x-3">
          <Image
            src={userAvatar}
            alt="Avatar"
            width={40}
            height={40}
            className="rounded-full border border-neutral-700"
          />
          <span className="text-white font-poppin text-xl font-bold">
            {userName}
          </span>
        </Link>

        {/* 🔹 Upload d'avatar */}

        {/* 🔹 Menu Desktop */}
        <div className="hidden md:flex space-x-8">
          <LogoutButton />
        </div>

        {/* 🔹 Bouton Hamburger pour Mobile */}
        <div className="md:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="focus:outline-none"
          >
            <motion.svg
              width="24"
              height="64"
              viewBox="0 0 24 24"
              initial="closed"
              animate={isMenuOpen ? "open" : "closed"}
            >
              <motion.line
                x1="3"
                y1="6"
                x2="21"
                y2="6"
                stroke="white"
                strokeWidth="2"
                animate={isMenuOpen ? { rotate: 45, y: 6 } : { rotate: 0, y: 0 }}
                transition={{ duration: 0.3 }}
              />
              <motion.line
                x1="3"
                y1="12"
                x2="21"
                y2="12"
                stroke="white"
                strokeWidth="2"
                animate={isMenuOpen ? { opacity: 0 } : { opacity: 1 }}
                transition={{ duration: 0.3 }}
              />
              <motion.line
                x1="3"
                y1="18"
                x2="21"
                y2="18"
                stroke="white"
                strokeWidth="2"
                animate={isMenuOpen ? { rotate: -45, y: -6 } : { rotate: 0, y: 0 }}
                transition={{ duration: 0.3 }}
              />
            </motion.svg>
          </button>
        </div>
      </nav>
    </header>
  );
}
